.link {
  color: var(--blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.tools {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dishTypeSelect {
  display: block;
  width: 200px;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.table {
  td, th {
    &:nth-child(3) {
      width: 200px;
    }

    &:nth-child(5) {
      width: 200px;
    }
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.addDish, .calculateNutrients {
  display: block;
  width: 140px;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 16px;
  text-decoration: none;
  text-align: center;
}

.calculateNutrients {
  width: 170px;
  background-color: var(--green);
}