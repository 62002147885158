.hidden {
  display: none;
}

.imageButton {
  display: block;
  width: 132px;
  height: 114px;
  line-height: 114px;
  text-align: center !important;
  border-radius: 0px;
  background: #ddd;
  cursor: pointer;
}

.imagePreview {
  display: block;
  width: 132px;
  height: 114px;
  border-radius: 0px;
  object-fit: contain;
}

.imageWrapper {
  position: relative;
  width: 132px;
}

.closeImage {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
  width: 21px;
  height: 21px;
  opacity: .7;
  cursor: pointer;
  transition: .2s linear;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, .2);

  &::before {
    content: "";
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    height: 16px;
    width: 2px;
    background-color: #e43030;
  }

  &::after {
    content: "";
    transform: rotate(-45deg);
    position: absolute;
    top: 3px;
    left: 9px;
    height: 16px;
    width: 2px;
    background-color: #e43030;
  }
}