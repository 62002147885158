.row {
  span {
    font-size: 14px;
  }

  .input, .select {
    margin-top: 5px;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 2px 0;

  input {
    width: 20px;
    height: 20px;
  }
}

.input, .select {
  display: block;
  width: 100%;
  height: 37px;
  padding: 0 16px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-sizing: border-box;
}

.textarea {
  height: 200px;
  padding: 10px 16px;
  resize: none;
}

.editor {
  height: 200px;
  padding-top: 5px;
  margin-bottom: 50px;
}

.dishComponentsWrapper {
  margin-bottom: 5px;
}

.dishComponents {
  font-size: 20px;
}

.componentBlock {
  margin-top: 10px;
  padding: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.saveButton {
  margin-top: 10px;
}

.ingredientsBlock {
  margin-top: 10px;
}

.serving {
  height: 100px;
  padding: 10px 16px;
  margin-top: 10px;
}

.dishImages {
  margin-top: 5px;
  margin-bottom: 5px;
}

.dishImagesTitle {
  font-size: 20px;
  margin-bottom: 5px;
}

.dishImageBlock {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
  padding: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.dishVideoBlock {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
  padding: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.forIngredient {
  font-size: 20px;
}

.servingRow {
  span {
    padding-top: 5px;
  }
}